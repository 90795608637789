
import { defineComponent } from "vue";
import TopBar from "@/components/TopBar.vue";
import Breadcrumb from "@/components/Breadcrumb.vue";
import Footer from "@/components/Footer.vue";
import ChatBox from "@/components/productsBox.vue";
import BackTop from "@/components/BackTop.vue";
import CookieBox from "@/components/CookieBox.vue";

export default defineComponent({
  name: "Service",
  components: {
    TopBar,
    Breadcrumb,
    Footer,
    ChatBox,
    BackTop,
    CookieBox,
  },
  metaInfo: {
    title: "B2B Marketplace - Chemicals & Steel - OTIMES",
    meta: [
      {
        name: "description",
        content:
          "Otimes International markets products from the world's leading petrochemical producers and facilitates their participation in the global supply chain management. Otimes focuses on understanding our petrochemical suppliers' goals and develops specific marketing plans accordingly. We provide customized supply chain management solutions for worldwide business partners.",
      },
      {
        name: "keywords",
        content:
          "OTIMES,PLA,PC,POM,PA,ABS,PP,Engineering Plastics,PLASTIC MATERIALS,STEEL,Cold Rolled Steel,Silicon Steel",
      },
    ],
  },
  setup() {
    return {};
  },
});
